import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Radio, Spin, Divider } from 'antd';
import axios from "axios";
import Swal from 'sweetalert2';
import {USER_SERVER} from '../../../../config'
import TestChat116 from './TestChat116';
//import TestCategory from './TestCategory';
import CreateAI from './CreateAI';
import MyPage from './MyPage';
import MyHistory from './MyHistory';
import TestSignUp from './TestSignUp';
import AdminAI from './AdminAI';

const { TextArea } = Input;

function TestHome116(props) {
    const [Id, setId] = useState(props.init_id);
    const [Token, setToken] = useState('');
    const [IsAdmin, setIsAdmin] = useState(false);
    const [Question, setQuestion] = useState('');
    const [TotalAnswer, setTotalAnswer] = useState('');
    const [Langcode2, setLangcode2] = useState('en');
    const [WaitAnswer, setWaitAnswer] = useState(false);
    //const [CurrAI, setCurrAI] = useState(null);
    const [CurrAIId, setCurrAIId] = useState('');
    const [ChatAIList, setChatAIList] = useState('');
    const [CategoryList, setCategoryList] = useState('');
    const [ScreenMode, setScreenMode] = useState('main')        //main, create_ai, my_page
    const [CountHistory, setCountHistory] = useState(0);
    const [RecentTs, setRecentTs] = useState(100);
    //const [DB, setDB] = useState('test');

    useEffect(() => {
        let val;
        const mem_name = 'mem'+CurrAIId;
        val = localStorage.getItem(mem_name); if (val) {setTotalAnswer(val);}

        val = localStorage.getItem('login_id'); if (val) {setId(val);}
    }, []);

    // async function reload_userinfo() {
    //     const res = await axios.post(`${USER_SERVER}/get_user`, {token:Token, user_id:Id});
    //     console.log('--------->', res);
    //     if (res.data.success){
    //         setQuotaUsage(res.data.data.quota_usage);
    //     }
    // }

    async function get_ailist_chat() {
        const res = await axios.post(`${USER_SERVER}/get_ailist_by_category`, {category_id:'chat', user_id:Id});
        //const res = await axios.post(`${USER_SERVER}/get_ailist_by_category`, {category_id:'chat', user_id:Id, page:0});
        console.log('get_ailist_chat--------->', res);
        if (res.data.success){
            setChatAIList(res.data.data.list);
        }
    }

    async function get_category_list() {
        const res = await axios.post(`${USER_SERVER}/get_category_list`, {lang:`${Langcode2}_US`});
        console.log('get_category_list--------->', res);
        if (res.data.success){
            setCategoryList(res.data.data);
        }
    }

    function get_mem_name(ai_id){
        return 'mem'+ai_id;
    }

    function onChangeId(e) {
        const id =  e.currentTarget.value;
        setId(id);
        localStorage.setItem('login_id', id);
    }

    async function onClickLogin(){
        // 일단 등록된 사용자인지 체크
        async function is_registered_user(user_id){
            const res = await axios.post(`${USER_SERVER}/is_registered_user`, {user_id});
            if (res.data.success){
                return res.data.data;
            } else{
                alert('Error:'+res.data.data);
                return false;
            }    
        }

        const registered = await is_registered_user(Id);
        if (!registered){
            alert('This id is not registered id. please sign up first');
            return;
        }

        // 실제 login 시작
        const dataToCommit = {
            token:Token, 
            user_id:Id,
            email:Id+'@rpg.com',
            name: 'name_' + Id
        }

        const res = await axios.post(`${USER_SERVER}/signin`, dataToCommit);
        console.log('--------->', res);
        if (res.data.success){
            if (res.data.data.method==='signin'){
                const userinfo = res.data.data.userinfo;
                //setLangcode2(userinfo.langcode.substring(0,2));
                setCurrAIId(userinfo.curr);
                setToken(userinfo['token']);
                if (userinfo['admin']) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }

                const mem_name = get_mem_name(userinfo.curr);
                const val = localStorage.getItem(mem_name); if (val) {setTotalAnswer(val);}
            } else {
                alert('Signed Up');
            }
        } else{
            alert('Error:'+res.data.data);
        }

        await get_ailist_chat();
        await get_category_list();
    }

    // AI를 선택하면 불리는 함수
    async function onClickAI(ai_id){
        let total_answer='';
        setScreenMode('main');
    
        const res = await axios.post(`${USER_SERVER}/start_chat`, {token:Token, user_id:Id, ai_id});
        console.log('start_chat--------->', res);
        if (res.data?.success) {
            ;
        } else {
            // const msg = 'start_chat returned false:'+res.data.data;
            // console.log(msg);
            // alert(msg);
            return
        }

        // 처음이라면 get_answer(get_introduction)
        const dataToCommit = {
            token:Token, 
            user_id:Id
        }
        
        const res1 = await axios.post(`${USER_SERVER}/get_introduction`, dataToCommit);
        console.log('get_introduction--------->', res1);

        // 처음 시작인 경우
        const mem_name = get_mem_name(ai_id);
        if (res1.data?.success) {
            const alist = res1.data.data;
            for (let k=0; k<alist.length; k++) {
                const part_a = 'STARTING MENT: ' + res1.data.data[k].answer;
                total_answer = total_answer + '\n' + part_a;
            }
            localStorage.setItem(mem_name, total_answer);
        } else {    // 이전 대화가 있는 경우
            total_answer = localStorage.getItem(mem_name);
        }

        //보조 정보를 보여주기 위해서...
        // dataToCommit.ai_id = ai_id
        // const res2 = await axios.post(`${USER_SERVER}/get_ai_info`, dataToCommit);
        // console.log('get_ai_info--------->', res2);
        // if (res2.data?.success) {
        //     debugger;
        //     setCurrAI(res2.data.data)
        // }

        setCurrAIId(ai_id);
        setTotalAnswer(total_answer);
    }

    async function onClickReset() {
        const ai_id = CurrAIId;
        let total_answer='';

        const res = await axios.post(`${USER_SERVER}/reset_history`, {token:Token, user_id:Id, ai_id: CurrAIId});
        console.log('reset_history--------->', res);
        if (res.data?.success) {
            //alert(res.data.data);
        } else {
            alert('Error:'+res.data.data);
        }

        // 처음이므로 get_answer(get_introduction)
        const dataToCommit = {
            token:Token, 
            user_id:Id
        }
        
        const res1 = await axios.post(`${USER_SERVER}/get_introduction`, dataToCommit);
        console.log('get_introduction--------->', res);
        if (res1.data?.success) {
            const alist = res1.data.data;
            for (let k=0; k<alist.length; k++) {
                const part_a = 'STARTING MENT: ' + res1.data.data[k].answer;
                total_answer = total_answer + '\n' + part_a;
            }
            const mem_name = get_mem_name(ai_id);
            localStorage.setItem(mem_name, total_answer);
            setTotalAnswer(total_answer);
        } else {
            alert(`Error: ${res1.data.data}`);
        }
    }

    async function onClickSend(){
        setWaitAnswer(true);

        const dataToCommit = {
            token:Token, 
            user_id:Id,
            question:Question.trim(),
            tts: false
        }
        
        const res = await axios.post(`${USER_SERVER}/get_answer`, dataToCommit);
        setWaitAnswer(false);
        console.log('--------->', res);
        if (res.data?.success) {
            const part_q = '\nUser: ' + Question.trim();
            const part_a = `----> ${res.data.data.answer} (${res.data.data.ts})`;
            const newtotal = TotalAnswer + '\n' + part_q + '\n' + part_a;

            const mem_name = get_mem_name(CurrAIId);
            localStorage.setItem(mem_name, newtotal);

            // count_history를 읽어온다
            const res1 = await axios.post(`${USER_SERVER}/get_count_history`, {ai_id:CurrAIId});
            if (res1.data?.success){
                setCountHistory(res1.data.data);
            }

            setTotalAnswer(newtotal);
            setRecentTs(res.data.data.ts);
        } else {
            //alert(`Error: ${res.data.data}`);
            Swal.fire({
                title: res.data.data.title,
                text: res.data.data.description,
                icon: 'info',
                confirmButtonText: '확인'
              });
        }

        //await reload_userinfo();        // 단지 update된 quota를 보여주기 위해서
    }

    async function onClickDeleteRecent(){
        setWaitAnswer(true);
        const dataToCommit = {
            token:Token, 
            user_id:Id,
            ts: RecentTs,
            ai_id: CurrAIId
        }
        
        const res = await axios.post(`${USER_SERVER}/delete_chat_from`, dataToCommit);
        setWaitAnswer(false);
        console.log('delete_chat_from --->', res);
        if (res.data?.success) {
            const msg = `deleted count = ${res.data.data.cnt}`;
            alert(msg);
        } else {
            //alert(`Error: ${res.data.data}`);
            Swal.fire({
                title: res.data.data.title,
                text: res.data.data.description,
                icon: 'info',
                confirmButtonText: '확인'
              });
        }

        //await reload_userinfo();        // 단지 update된 quota를 보여주기 위해서
    }

    async function onClickRegenerate(){
        setWaitAnswer(true);
        const dataToCommit = {
            token:Token, 
            user_id:Id,
            question:Question.trim(),
            tts: false,
            ts: RecentTs
        }
        
        const res = await axios.post(`${USER_SERVER}/regenerate`, dataToCommit);
        setWaitAnswer(false);
        console.log('regenerate --->', res);
        if (res.data?.success) {
            const part_q = '\nUser: ' + Question.trim();
            const part_a = `----> ${res.data.data.answer} (${res.data.data.ts})`;
            const newtotal = TotalAnswer + '\n' + part_q + '\n' + part_a;

            const mem_name = get_mem_name(CurrAIId);
            localStorage.setItem(mem_name, newtotal);

            // count_history를 읽어온다
            const res1 = await axios.post(`${USER_SERVER}/get_count_history`, {ai_id:CurrAIId});
            if (res1.data?.success){
                setCountHistory(res1.data.data);
            }

            setTotalAnswer(newtotal);
            setRecentTs(res.data.data.ts);
        } else {
            //alert(`Error: ${res.data.data}`);
            Swal.fire({
                title: res.data.data.title,
                text: res.data.data.description,
                icon: 'info',
                confirmButtonText: '확인'
              });
        }

        //await reload_userinfo();        // 단지 update된 quota를 보여주기 위해서
    }


    function onBack() {
        setScreenMode('main');
    }

    async function onBackReload() {
        await get_ailist_chat();
        await get_category_list();
        setScreenMode('main');
    }

    // async function onChangeDB(e) {
    //     const new_db = e.target.value;
    //     const res = await axios.post(`${USER_SERVER}/change_db`, {db:new_db});
    //     console.log('--------->', res);
    //     if (res.data?.success) {
    //         setDB(new_db);
    //     } else {
    //         alert('Error:'+res.data.data);
    //     }        
    // }
  
    return (        
        <div className='container'>
            {
                ScreenMode==='create_ai'?  <CreateAI token={Token} user_id={Id} onBack={onBackReload}/> :
                ScreenMode==='my_page'? <MyPage token={Token} user_id={Id} onBack={onBack}/> : 
                ScreenMode==='history'? <MyHistory token={Token} user_id={Id} onBack={onBack} onClickAI={onClickAI}/> : 
                ScreenMode==='admin_ai'? <AdminAI token={Token} user_id={Id} onBack={onBack} onClickAI={onClickAI}/> : 
                ScreenMode==='signup'? <TestSignUp onBack={onBack}/> : 

                <div>
                <Row>

                    <Col span={8}>
                        {/* <Row align='middle' style={{marginTop:20}}>
                            <Col span={16}>
                                Select DB(Test server에서만 동작함):
                            </Col>
                            <Col span={8}>
                                <Radio.Group onChange={onChangeDB} value={DB}>
                                <Radio value='test'>Test DB</Radio>
                                <Radio value='live' style={{ color: 'red' }}> Live DB</Radio>
                                </Radio.Group>                
                            </Col>

                        </Row> */}
                        <Row align='middle' style={{marginTop:20}}>
                            새로운 ID를 만들려면 SignUp을 먼저 해주세요.
                            <Button type='primary' onClick={()=>setScreenMode('signup')}>Sign Up</Button>
                        </Row>
                        <Row align='middle' style={{margin:'20px'}}>
                            <Col>Id:</Col>
                            <Col>
                                <Input value={Id} onChange={onChangeId} onPressEnter={onClickLogin} lang='en'/>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={onClickLogin}>Login</Button>
                            </Col>
                        </Row>
                        <Row>
                            Language = {Langcode2}
                        </Row>
    
                        <Row style={{marginTop:20}}>
                            <TestChat116 onClickAI={onClickAI} ChatAIList={ChatAIList}/>
                        </Row>
                        {/* <Row style={{marginTop:20}}>
                            <TestCategory onClickAI={onClickAI} CategoryList={CategoryList} UserId={Id}/>
                        </Row> */}
    
                        {/* <Row style={{marginTop:20}}>
                        Subscription : {QuotaUsage[0]}<br/>
                        {QuotaUsage[1]}<br/>
                        {QuotaUsage[2]}<br/>
                        </Row> */}

                        <Divider/>
    
                        <Row>
                            <Col><Button type='primary' disabled={!Token} onClick={()=>{setScreenMode('create_ai')}} style={{margin:10}}>Create AI</Button></Col>
                            <Col><Button type='primary' disabled={!Token} onClick={()=>{setScreenMode('my_page')}} style={{margin:10}}>My Page</Button></Col>
                            <Col><Button type='primary' disabled={!Token} onClick={()=>{setScreenMode('history')}} style={{margin:10}}>History</Button></Col>
                            {IsAdmin? <Col><Button type='primary' disabled={!Token} onClick={()=>{setScreenMode('admin_ai')}} style={{margin:10}}>Admin AI</Button></Col>:''}
                        </Row>
                    </Col>
                    
                    <Col span={15}>
                        <Row justify="center">
                            <Col span={22}>
                                <TextArea rows={20} value={TotalAnswer}/>
                            </Col>
                        </Row>
    
                        <Row justify="center" align='middle' style={{marginTop:20}}>
                            <Col span={2}>
                                Player:
                            </Col>
                            <Col span={20}>
                                <Input value={Question} onChange={(e)=>setQuestion(e.currentTarget.value)} onPressEnter={onClickSend}/>
                            </Col>
                            <Col span={1}>
                                {
                                    WaitAnswer? <Spin/> :
                                                <Button type='primary' onClick={onClickSend}>
                                                    Send
                                                </Button>
                                }
                            </Col>
                        </Row>
    
                        <Row>
                            <Col>
                                AI:{CurrAIId}, count:{CountHistory}
                            </Col>
                        </Row>
                        <Row justify="center" align='middle'>
                            <Col>
                                <Button type='primary' onClick={onClickRegenerate} style={{marginLeft:30}}>Regenerate</Button>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={onClickDeleteRecent} style={{marginLeft:30}}>Delete Recent</Button>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={onClickReset} style={{marginLeft:30}}>Reset and Restart</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            }
            
        </div>
    )
}

export default TestHome116
