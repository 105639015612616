import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Radio, Spin, Divider } from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../../config'
//import TestChat116 from './TestChat116';
//import TestCategory from './TestCategory';
//import CreateAI from './CreateAI';
//import MyPage from './MyPage';

const { TextArea } = Input;

function TestSignUp(props) {
    const [Id, setId] = useState('');
    const [Email, setEmail] = useState('');
    const [Nickname, setNickname] = useState('');
    const [UserGender, setUserGender] = useState('unknown');
    const [WantGender, setWantGender] = useState('female');
    //const [Lang, setLang] = useState('en_US');

    function onChangeId(e) {
        const id = e.currentTarget.value;
        setId(id);
        localStorage.setItem('login_id', id);
    }

    async function onClickSignUp() {
        let token = '';
        const dataToCommit = {
            user_id:Id,
            email: Id + '@xxx.com'
        }
        
        const res = await axios.post(`${USER_SERVER}/signin`, dataToCommit);
        console.log('--------->', res);
        if (res.data.success){
            if (res.data.data.method==='signup'){
                //alert('SignUp success');
                token = res.data.data.userinfo.token;
                //props.onBack();
            } else {
                alert('There exist this id already');
            }
        } else{
            alert('Error:'+res.data.data);
        }

        // write_db test
        const dataToCommit1 = {
            token: token,
            user_id: Id,
            nickname: Nickname,
            gender:UserGender,
            want_gender:WantGender
        }
        
        const res1 = await axios.post(`${USER_SERVER}/write_db`, dataToCommit1);
        console.log('--------->', res1);
        if (res1.data.success){
            alert('SignUp success');
            props.onBack();
        } else{
            alert('Error:'+res1.data.data);
        }
    }


    return (        
        <div>
            <Row>
                <Col>phone의 언어를 선택해주세요.</Col>
            </Row>
            {/* <Row>
                <Col>
                    <Radio.Group onChange={(e)=>setLang(e.target.value)} value={Lang}>
                    <Radio value='en_US'>en_US</Radio>
                    <Radio value='ko_KR'>ko_KR</Radio>
                    <Radio value='ja_JP'>ja_JP</Radio>
                    </Radio.Group>                
                </Col>
            </Row> */}
            <Row align='middle' style={{margin:'20px'}}>
                <Col>Id:</Col>
                <Col>
                    <Input value={Id} onChange={onChangeId}/>
                </Col>
            </Row>
            <Row align='middle' style={{margin:'20px'}}>
                <Col>대화에서 사용할 당신의 nickname:</Col>
                <Col>
                    <Input value={Nickname} onChange={(e)=>setNickname(e.target.value)}/>
                </Col>
            </Row>

            <Divider/>
            <Row align='middle' style={{margin:'20px'}}>
                Your Pronouns:
            </Row>
            <Row align='middle' style={{margin:'20px'}}>
                <Radio.Group onChange={(e)=>setUserGender(e.target.value)} value={UserGender}>
                <Radio value='male'>He</Radio>
                <Radio value='female'>She</Radio>
                <Radio value='unknown'>They</Radio>
                </Radio.Group>                
            </Row>


            <Divider/>
            <Row align='middle' style={{margin:'20px'}}>
                Preferred Partner:
            </Row>
            <Row align='middle' style={{margin:'20px'}}>
                <Radio.Group onChange={(e)=>setWantGender(e.target.value)} value={WantGender}>
                <Radio value='male'>Man</Radio>
                <Radio value='female'>Woman</Radio>
                <Radio value='anyone'>Anyone</Radio>
                </Radio.Group>                
            </Row>

            <Divider/>
            <Row align='middle' style={{margin:'20px'}}>
                <Col>
                    <Button type='primary' onClick={props.onBack} style={{margin:10}}>Back</Button>
                </Col>
                <Col>
                    <Button type='primary' onClick={onClickSignUp} style={{margin:10}}>Sign Up</Button>
                </Col>
            </Row>
        </div>
    )
}

export default TestSignUp
