import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Card, Spin } from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../../config'

function MyHistory(props) {
    const user_id = props.user_id;
    const token = props.token;
    const { Meta } = Card;
    const [AiList, setAiList] = useState(null);

    useEffect(()=>{
        get_ailist_by_access();
    }, []);

    async function onClickAI(ai){
        props.onClickAI(ai.id);
    }

    async function get_ailist_by_access() {
        const res = await axios.post(`${USER_SERVER}/get_ailist_by_access`, {token,user_id}).catch(err=>{console.log(err.message); throw err;});
        if (res.data.success) {
            console.log("get_ailist_by_access======>", res.data);
            setAiList(res.data.data.list);
        } else {
            alert("get_ailist_by_access:"+res.data.errmsg);
        }
    }

    const render_ai_list = AiList && AiList.map(function(ai, index){
        return (
            <Col span={6} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card cover={<img alt="photo" src={ai.photo_url} />} onClick={()=>onClickAI(ai)} style={{width:200, cursor:'pointer'}}>
                    <Meta title={ai.bold}/>
                    {ai.author_nickname}
                </Card>
            </Col>
        );
		}
    );


    return (        
        <div>
            {AiList==null ? <Spin/>:
                <div>
                    <Row style={{marginTop:30, marginBottom:20}}>
                        <h2>AI List Accessed by Me</h2>
                    </Row>

                    <Row justify='space-around'>
                        {render_ai_list}
                    </Row>

                    <Row justify='center'>
                        <Button type='primary' onClick={props.onBack}>Back</Button>
                    </Row>
                </div>            
            }

        </div>
    )
}

export default MyHistory
