import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Spin } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER} from '../../../../config'

function TestFunction116(props) {

    const [Id, setId] = useState(props.init_id);
    const [Question, setQuestion] = useState('');
    const [Answer, setAnswer] = useState('');
    const [Intimacy, setIntimacy] = useState('');
    const [Internal, setInternal] = useState('');
    const [AnswerMp3, setAnswerMp3] = useState(null);
    const [LenUserlist, setLenUserlist] = useState(0);
    const [CntRecent, setCntRecent] = useState(0);
    const [CntAnswer, setCntAnswer] = useState(0);
    const [RedisMem, setRedisMem] = useState('');
    const [NumCleanupCandidate, setNumCleanupCandidate] = useState(0);
    const [RemainTimeSchedule, setRemainTimeSchedule] = useState(0);
    const [TTS, setTTS] = useState(false);
    const [audioPlayer, setAudioPlayer] = useState(new Audio());
    const [Langcode2, setLangcode2] = useState('en');
    const [WaitAnswer, setWaitAnswer] = useState(false);
    const [API, setAPI] = useState('');
    const [APIParam, setAPIParam] = useState('');

    useEffect(()=>{
        get_system_info();
    }, []);

    useEffect(() => {
        let val;
        val = localStorage.getItem('login_id'); if (val) {setId(val);}        
        val = localStorage.getItem('api'); if (val) {setAPI(val);} 
        val = localStorage.getItem('api_param'); if (val) {setAPIParam(val);} 
    }, []);


    //////////////////////////////////////////////////////////////
    async function onClickGeneralAPITest() {
        //debugger;
        localStorage.setItem('api', API);
        localStorage.setItem('api_param', APIParam);

        let param;
        try {
            param = JSON.parse(APIParam);
        } catch {
            alert('invalid json?');
        }

        const res = await axios.post(`${USER_SERVER}/${API}`, param);
        console.log(`${API}--------->`, res);
    }

    async function onClickGeneralAPITestMultiple() {
        await Promise.all([onClickGeneralAPITest(), onClickGeneralAPITest(), onClickGeneralAPITest(), onClickGeneralAPITest()]);
    }

    async function onClickTmpTest() {
        const dataToCommit = {
            id:Id, 
            new:'주니'
        }

        const res = await axios.post(`${USER_SERVER}/tmp_test_for_debug`, dataToCommit);
        console.log('tmp_test_for_debug--------->', res);
        if (res.data.success){
            alert('Login:success');
        } else{
            alert('Error:'+res.data.data);
        }
    }

    async function onClickUpdateStatus() {
        get_system_info();    
    }

    function onChangeId(e) {
        const id = e.currentTarget.value;
        setId(id);
        localStorage.setItem('login_id', id);
    }
    async function onClickLogin(){
        const dataToCommit = {
            id:Id,
            email:Id+'@gmail.com',
            name: 'name_' + Id
        }
        
        const res = await axios.post(`${USER_SERVER}/signin`, dataToCommit);
        console.log('--------->', res);
        if (res.data.success){
            alert('Login:success:'+ res.data.data.method);
        } else{
            alert('Error:'+res.data.data);
        }

        await get_system_info();
    }

    //////////////////////////////////////////////////////////////
    function onChangeQuestion(e) {
        setQuestion(e.currentTarget.value);
    }

    function base64ToBinary(base64String) {
        const binaryString = atob(base64String);
        const binaryData = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          binaryData[i] = binaryString.charCodeAt(i);
        }
        return binaryData;
    }

    async function onClickSend(){
        setWaitAnswer(true);

        const dataToCommit = {
            id:Id,
            //app_ver:'1.1.0',
            question:Question.trim(),
            tts: TTS
        }
        
        const res = await axios.post(`${USER_SERVER}/get_answer`, dataToCommit);
        setWaitAnswer(false);
        console.log('--------->', res);
        debugger;
        if (res.data?.success) {
            setAnswer(res.data.data);
            setIntimacy(res.data.intimacy);
            setInternal(res.data.internal);
            if (TTS) {
                const binary_mp3 = base64ToBinary(res.data.tts);
                setAnswerMp3(binary_mp3);
            }
        } else {
            let msg = JSON.stringify(res.data.data);
            alert(`Error: ${msg}`);
        }

        await get_system_info();
    }

    //////////////////////////////////////////////////////////////
    async function onClickLogout(){
        const dataToCommit = {
            id:Id
        }
        
        const res = await axios.post(`${USER_SERVER}/signout`, dataToCommit);
        console.log('--------->', res);
        if (res.data?.success) {
            alert('Logout Success');
        }
    }

    //////////////////////////////////////////////////////////////
    async function onClickRetire(){
        const dataToCommit = {
            id:Id
        }
        
        const res = await axios.post(`${USER_SERVER}/retire`, dataToCommit);
        console.log('--------->', res);
        if (res.data?.success) {
            alert('Retire Success');
        } else {
            alert('Error:'+res.data.data);
        }
    }

    //////////////////////////////////////////////////////////////
    async function onClickGetUser(){
        const res = await axios.post(`${USER_SERVER}/get_user`, {id:Id});
        console.log('--------->', res);
        if (res.data?.success) {
            alert(res.data.data);
        } else {
            alert('Error:'+res.data.data);
        }

        //////////////////
        console.log('before parter info');
        const res1 = await axios.post(`${USER_SERVER}/get_partner_info`, {'id':'AD4RVNM1MEMvJSiQU4ePnDnZ6VI2'});
        console.log('partner--------->', res1);
        if (res1.data?.success) {
            //alert(res1.data.data);
        } else {
            alert('Error:'+res1.data.data);
        }

    }

    async function get_system_info() {
        const res = await axios.get(`${USER_SERVER}/get_system_info`);
        console.log('after get_system_info', res.data.data);
        if (res.data?.success) {
            setLenUserlist(res.data.data.len_userlist);
            setCntRecent(res.data.data.cnt_recent);
            setCntAnswer(res.data.data.cnt_answer);
            setRedisMem(res.data.data.redis_meminfo.used_memory);
            setNumCleanupCandidate(res.data.data.num_cleanup_candidate);
            setRemainTimeSchedule(parseInt(res.data.data.remain_time_schedule));
        } else {
            alert('Error:'+res.data.data);
        }
    }

    //////////////////////////////////////////////////////////////
    async function onClickStartApp(){
        const dataToCommit = {
            device_id:'did-'+Id,
            message:'log testing'
        }
        
        const res = await axios.post(`${USER_SERVER}/on_log`, dataToCommit);
        console.log('--------->', res);
        if (res.data?.success) {
            alert(res.data.data);
        } else {
            alert('Error:'+res.data.data);
        }
    }

    //////////////////////////////////////////////////////////////
    async function onClickPartnerList(){
        const dataToCommit = {
            id:Id,
            country:'ja_JP'
        }
        
        const res = await axios.post(`${USER_SERVER}/get_partner_list`, dataToCommit);
        console.log('--------->', res);
        if (res.data?.success) {
            alert(res.data.data);
        } else {
            alert('Error:'+res.data.data);
        }
    }

    async function onClickGetBackgroundList(){
        const dataToCommit = {
            id:Id,
            country:'ja_JP'
        }
        
        const res = await axios.post(`${USER_SERVER}/get_background_list`, dataToCommit);
        console.log('--------->', res);
        if (res.data?.success) {
            alert(res.data.data);
        } else {
            alert('Error:'+res.data.data);
        }
    }

    ////////////////////////////////////////////////////////////////
    function onChangeTTS() {
        setTTS(!TTS);        
    }

    const playAudio = () => {
        const audioBlob = new Blob([AnswerMp3], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioPlayer.src = audioUrl;
        audioPlayer.play();
      };

    async function onClickSelectFriend() {
        let dataToCommit;
        if (Langcode2=='ko') {
            dataToCommit = {
                id:Id,
                nickname:'현빈',
                partner: '하나',
                langcode: 'ko_KR',
                level: 0,
                sub_level: 0,
                star_point: 0,
                show_mind: true
            }
        }
        else if (Langcode2=='ja'){
            dataToCommit = {
                id:Id,
                nickname:'jake',
                partner: 'ミユキ',
                langcode: 'ja_JP',
                level: 0,
                sub_level: 0,
                star_point: 0,
                show_mind: true
            }
        }
        else if (Langcode2=='en'){
            dataToCommit = {
                id:Id,
                nickname:'jake',
                partner: 'Fiona',
                langcode: 'en_US',
                level: 0,
                sub_level: 0,
                star_point: 0,
                show_mind: true
            }
        } else {
            alert('unknown langcode2 (ko, ja, en)')
        }

        const res = await axios.post(`${USER_SERVER}/write_db`, dataToCommit);
        console.log('--------->', res);
        if (res.data?.success) {
            alert(res.data.data);
        } else {
            alert('Error:'+res.data.data);
        }

    }


    return (
        <div>
            <h3>TESTING</h3> 
            Length of Userlist : {LenUserlist}  <br/>
            2분내에 사용한 User수 : {CntRecent}  <br/>
            지금 get_answer를 하고있는 user수 : {CntAnswer}  <br/>
            redis memory usage : {RedisMem}  <br/>
            다음 schedule 까지 남은 초 : {RemainTimeSchedule}  <br/>
            cleanup candidate 수: {NumCleanupCandidate}  <br/>
            <Button onClick={onClickUpdateStatus}>Update</Button>

            <Row style={{margin:'20px'}}>
                <Col>Id:</Col>
                <Col>
                    <Input value={Id} onChange={onChangeId} onPressEnter={onClickLogin} lang='en'/>
                </Col>
                <Col>
                    <Button type='primary' onClick={onClickLogin}>Login</Button>
                </Col>
            </Row>

            <Row style={{margin:'20px'}}>
                Langcode2: <Input value={Langcode2} onChange={(e)=>setLangcode2(e.currentTarget.value)} lang='en' style={{width:100, marginRight:10}}/>
                <Button type='primary' onClick={onClickSelectFriend}>
                    Select Friend
                </Button>
            </Row>

            <Row style={{margin:'20px'}}>
                Question:
                <Input value={Question} onChange={onChangeQuestion} onPressEnter={onClickSend}/>
                {
                    WaitAnswer? <Spin/> :
                                <Button type='primary' onClick={onClickSend}>
                                    Send
                                </Button>
                }
            </Row>

            TTS: <Switch checked={TTS} onChange={onChangeTTS} checkedChildren='TTS'/>
            <button onClick={playAudio}>Play Audio</button>

            <Row style={{margin:'20px'}}>
                Answer: <br/>
                {Answer}
            </Row>

            <Row style={{margin:'20px'}}>
                친밀도: <br/>
                {Intimacy}
            </Row>

            <Row style={{margin:'20px'}}>
                Internal Thought: <br/>
                {Internal}
            </Row>

            <Row style={{margin:'20px'}}>
                General API Test:
                <Input value={API} onChange={(e)=>setAPI(e.currentTarget.value)}/>
                {" param (Ex: {\"id\" : \"1234\"}): "}
                <Input value={APIParam} onChange={(e)=>setAPIParam(e.currentTarget.value)}/>
                {
                    WaitAnswer? <Spin/> :
                                <div>
                                    <Button type='primary' style={{margin:10}} onClick={onClickGeneralAPITest}>
                                        Send
                                    </Button>
                                    <Button type='primary' style={{margin:10}} onClick={onClickGeneralAPITestMultiple}>
                                        Send Multiple
                                    </Button>
                                </div>
}
            </Row>

            <Row style={{margin:'20px'}}>
                <Button type='primary' onClick={onClickLogout}>
                    Logout
                </Button>
            </Row>

            <Row style={{margin:'20px'}}>
                <Button type='primary' onClick={onClickRetire}>
                    계정 탈퇴
                </Button>
            </Row>

            <Row style={{margin:'20px'}}>
                <Button type='primary' onClick={onClickGetUser}>
                    사용자 정보 가져오기
                </Button>
            </Row>

            <Row style={{margin:'20px'}}>
                <Button type='primary' onClick={onClickStartApp}>
                    앱 시작시 db저장 test
                </Button>
            </Row>

            <Row style={{margin:'20px'}}>
                <Button type='primary' onClick={onClickPartnerList}>
                    앱 시작시 db저장 test
                </Button>
            </Row>

            <Row style={{margin:'6px'}}>
                <Button type='primary' onClick={onClickGetBackgroundList}>
                    Get Background List
                </Button>
            </Row>

            <Row style={{margin:'6px'}}>
                <Button type='primary' onClick={onClickTmpTest}>
                    TmpTest
                </Button>
            </Row>

        </div>
    )
}

export default TestFunction116
