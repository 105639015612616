import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Radio } from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../../config'
const { TextArea } = Input;

function EditAIExpert(props) {
    const user_id = props.user_id;
    const token = props.token;
    const curr = props.ai;
    const [category_list, setcategory_list] = useState([]);
    const [Title, setTitle] = useState('');
    const [Visibility, setVisibility] = useState(0);
    const [Category, setCategory] = useState(0);
    const [Gender, setGender] = useState(0);
    const [OnelineDesc, setOnelineDesc] = useState('');
    const [Greeting, setGreeting] = useState('');
    const [Description, setDescription] = useState('');
    const [Prompt, setPrompt] = useState('');
    const [MyFile, setMyFile] = useState(null);
    const [ImageUrl, setImageUrl] = useState('');

    useEffect(()=>{
        async function asyncfunc() {
            const res = await axios.post(`${USER_SERVER}/get_category_list`, {lang:'en_US', for_create:true}).catch(err=>{console.log(err.message); throw err;});
            let tmp_category_list=[];
            if (res.data.success) {
                console.log("======;;;;;;", res.data);
                tmp_category_list = res.data.data;
                setcategory_list(tmp_category_list);
            } else {
                alert("Failed to upload file:"+res.data.errmsg);
            }
        }

        asyncfunc();
    }, []);

    useEffect(()=>{
        function category_to_id(category_name, category_list){
            for (let k=0; k<category_list.length; k++){
                if (category_name===category_list[k].id) {
                    return k;
                }                
            }
            return -1;
        }
        // category list를 id로 변환
        const category_id = category_to_id(curr.category, category_list);
        setCategory(category_id);
        setGender(curr.gender);
        setTitle(curr.name);
        setOnelineDesc(curr.normal);
        setGreeting(curr.intro_list[0]);
        setVisibility(curr.visibility);
        setDescription(curr.description);
        setPrompt(curr.prompt);
    }, [curr, category_list]);

    function onChangeVisibility(e) {
      console.log('radio checked', e.target.value);
      const idx = e.target.value;
      setVisibility(e.target.value);
    };

    function onChangeCategory(e) {
        console.log('radio checked', e.target.value);
        const idx = e.target.value;
        setCategory(e.target.value);

        // if (category_list[e.target.value].id==='ai_boy'){
        //     setGender('male');
        // }
        // else if (category_list[e.target.value].id==='ai_girl'){
        //     setGender('female');
        // }
    };
  
    function onChangeGender(e) {
        console.log('radio checked', e.target.value);
        setGender(e.target.value);
    };

    async function onClickModify(){
        let formData = new FormData();
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
    
        formData.append('token', token);
        formData.append('user_id', user_id);
        formData.append('ai_id', curr.id);
        formData.append('name', Title);
        formData.append('oneline_desc', OnelineDesc);
        formData.append('greeting', Greeting);
        formData.append('description', Description);
        formData.append('prompt', Prompt);
        formData.append('visibility', Visibility);
        formData.append('category', category_list[Category].id);
        formData.append('gender', Gender);
        formData.append('image_url', ImageUrl);
        if (MyFile) {
            formData.append("image", MyFile);
        }

        // main contents를 upload함
        const res = await axios.post(`${USER_SERVER}/update_ai_expert`, formData, config).catch(err=>{console.log(err.message); throw err;});
    
        if (res.data.success) {
            console.log("contents upload success:", res.data);
            alert('modification success!')
            props.onBack();
        } else {
            alert("Failed to upload file:"+res.data.errmsg);
        }
    }

    // 사용자 pc에서 이미지를 선택할 때
    function onChangeFile(e) {
        setMyFile(e.target.files[0])
        setImageUrl('');
    }

    // backend의 album에서 image를 선택할 때
    function onClickSelectAlbum(){
        setImageUrl('https://heraigirl.s3.ap-northeast-2.amazonaws.com/ai_album/ive2.jpg');
        setMyFile(null);
        const fileInput = document.getElementById('fileInput');
        fileInput.value='';
    }

    const render_category_list = category_list && category_list.map(function(category, index){
        // console.log(ai);
        //debugger;
        return (
            <Radio key={index} value={index}>{category.name}</Radio>
        );
		}
    );


    return (        
        <div>
            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Title:
                </Col>
                <Col span={16}>
                    <Input value={Title} onChange={(e)=>{setTitle(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row justify='center'>
                <img src={curr.photo_url} alt="Description of image" width={120}/>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Image:
                </Col>
                <Col>
                    <Row>
                        <input type='file' id='fileInput' onChange={onChangeFile}/>
                    </Row>
                    <Row>
                        OR
                    </Row>
                    <Row align='middle'>                       
                        <Col style={{marginRight:20}}><Button onClick={onClickSelectAlbum}>Select From Album</Button></Col> 
                        <Col>{ImageUrl}</Col>                        
                    </Row>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Oneline Description:
                </Col>
                <Col span={16}>
                    <TextArea value={OnelineDesc} rows={4} showCount maxLength={300} onChange={(e)=>{setOnelineDesc(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Greeting:
                </Col>
                <Col span={16}>
                    <TextArea value={Greeting} rows={4} showCount maxLength={400} onChange={(e)=>{setGreeting(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Visibility:
                </Col>
                <Col span={16}>
                    <Radio.Group onChange={onChangeVisibility} value={Visibility}>
                    <Radio value='public'>public</Radio>
                    <Radio value='private'>private</Radio>
                    </Radio.Group>                
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Category:
                </Col>
                <Col span={16}>
                    <Radio.Group onChange={onChangeCategory} value={Category}>
                        {render_category_list}
                    </Radio.Group>                
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Gender:
                </Col>
                <Col span={16}>
                    <Radio.Group onChange={onChangeGender} value={Gender}>
                    <Radio value='male'>male</Radio>
                    <Radio value='female'>female</Radio>
                    </Radio.Group>                
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Description:
                </Col>
                <Col span={16}>
                    <TextArea value={Description} rows={8} showCount maxLength={1600} onChange={(e)=>{setDescription(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Prompt:
                </Col>
                <Col span={16}>
                    <TextArea value={Prompt} rows={8} showCount maxLength={6000} onChange={(e)=>{setPrompt(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row justify='center' style={{marginTop:30}}>
                <Button type='primary' onClick={props.onBack} style = {{margin:10}}>Back</Button>
                <Button type='primary' onClick={onClickModify} style = {{margin:10}}>Upload Change</Button>
            </Row>

        </div>
    )
}

export default EditAIExpert
