import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Card, Checkbox, Modal, Input, Pagination, Spin } from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../../config'
import EditAINovice from './EditAINovice';
//import EditAIExpert from './EditAIExpert';

const { TextArea } = Input;

function AdminAI(props) {
    const num_per_page = 20;
    const user_id = props.user_id;
    const token = props.token;
    const { Meta } = Card;
    const [AiList, setAiList] = useState([]);
    const [EditMode, setEditMode] = useState(false);
    //const [ExpertMode, setExpertMode] = useState('novice');
    const [CurrAI, setCurrAI] = useState(null);
    const [FiltUnApproved, setFiltUnApproved] = useState(true);   
    const [MoreData, setMoreData] = useState(false);
    const [TotalPage, setTotalPage] = useState(0);
    const [CurrentPage, setCurrentPage] = useState(1);
    const [Loading, setLoading] = useState(false);
    const [SearchText, setSearchText] = useState('');

    useEffect(()=>{
        get_ailist_all(CurrentPage);
    }, [FiltUnApproved, CurrentPage]);

    async function onClickAI(ai){
        setCurrAI(ai);
        setEditMode(true);
    }

    // function onChangeExpertMode(e) {
    //     const idx = e.target.value;
    //     setExpertMode(e.target.value);
    // };

    async function get_ailist_all(current_page) {
        setLoading(true);
        const dataToCommit ={
            token,
            user_id,
            FiltUnApproved,
            page:current_page,
            num_per_page,
            search_text:SearchText,
        }
        const res = await axios.post(`${USER_SERVER}/get_ailist_all`, dataToCommit).catch(err=>{console.log(err.message); throw err;});
        if (res.data.success) {
            console.log("get_ailist_all======>", res.data);
            setAiList(res.data.data.list);
            setMoreData(res.data.data.more_data_exist);
            setTotalPage(res.data.data.total_num_page);
        } else {
            alert("Failed to upload file:"+res.data.errmsg);
        }
        setLoading(false);
    }

    // async function onClickDeleteAI(e, ai_id){
    //     e.stopPropagation();
    //     const res = await axios.post(`${USER_SERVER}/delete_ai`, {token,user_id, ai_id}).catch(err=>{console.log(err.message); throw err;});
    //     if (res.data.success) {
    //         console.log("======>", res.data);
    //         get_ailist_by_author();
    //     } else {
    //         alert("Error:onClickDeleteAI"+res.data.errmsg);
    //     }
    // }

    async function onChangeApprove(ai, approve) {
        const dataToCommit ={
            token,
            user_id,
            ai_id: ai.id,
            filtApproved: approve
        }
        const res = await axios.post(`${USER_SERVER}/change_ai_approve`, dataToCommit).catch(err=>{console.log(err.message); throw err;});
        if (res.data.success) {
            console.log("change_ai_approve======>", res.data);
            ai.filtApproved = approve;
            const list_copy=[...AiList];
            // for (let k=0; k<AiList.length; k++){
            //     if (AiList[k].id===ai.id
            // }
            setAiList(list_copy);
        } else {
            alert("Failed to change_ai_approve:"+res.data.errmsg);
        }

    }

    const render_ai_list = AiList && AiList.map(function(ai, index){
        let approve = false;
        if (ai.hasOwnProperty('filtApproved')) {
            approve = ai['filtApproved'];
        }

        return (
            <tr key={index}>
                <td> <img alt="photo" src={ai.photo_url} height={50} onClick={()=>onClickAI(ai)}/> </td>
                <td> {ai.bold} </td>
                <td> {ai.normal} </td>
                <td> {ai.visibility}</td>
                <td> <Checkbox checked={approve} onChange={(e)=>onChangeApprove(ai, e.target.checked)}></Checkbox> </td>
            </tr>

        );
		}
    );

    async function reload() {
        await get_ailist_all(CurrentPage);
        setEditMode(false);
    }

    function onClickReload() {
        if (CurrentPage==1) {
            get_ailist_all(CurrentPage);
        } else {
            setCurrentPage(1);
        }
        
    }

    function onChangeSearch(e) {
        setSearchText(e.target.value);
    }


    return (        
            <div>
                <Checkbox checked={FiltUnApproved} onChange={(e)=>setFiltUnApproved(e.target.checked)}>UnApproved</Checkbox>

                <Row>
                    <Col xs={8}>
                        <Input placeholder="search" value={SearchText} onChange={onChangeSearch}/>
                    </Col>
                    <Col xs={8}>
                        <Button onClick={onClickReload}>Reload</Button>
                    </Col>
                </Row>

                <Row style={{marginTop:30, marginBottom:20}}>
                    <h2>All AI List Created by All Users</h2>
                </Row>

                { Loading? <Spin tip="Loading..." /> : 
                <Row justify='space-around'>
                    <table style={{ width: '80%' }}>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Desc.</th>
                                <th>public</th>
                                <th>Approve</th>
                            </tr>
                        </thead>
                        <tbody>
                            {render_ai_list}
                        </tbody>
                    </table>
                </Row>}

                <Row justify='center'>
                    <Col>
                        <Button type='primary' onClick={props.onBack}>Back</Button>
                    </Col>
                </Row>

                <Row>
                    <Pagination
                        current={CurrentPage}
                        total={TotalPage * num_per_page} // 가정: 각 페이지에 10개의 아이템이 있음
                        pageSize={num_per_page}
                        onChange={(page)=>setCurrentPage(page)}
                        showSizeChanger={false} // 페이지 크기 변경 옵션 숨김
                        showQuickJumper={true} // 페이지 직접 입력 옵션 활성화
                        disabled={false}
                    />
                </Row>


                <Modal open={EditMode} footer={null} span={22}>
                    <EditAINovice user_id={user_id} token={token} ai={CurrAI} onBack={()=>setEditMode(false)} onReload={reload}/>
                </Modal>
            </div>
    )
}

export default AdminAI
